import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  DebugElement,
} from "@angular/core";
import * as THREE from "three";
import { TweenMax, Power2 } from "gsap";
import {
  CSS2DRenderer,
  CSS2DObject,
} from "three/examples/jsm/renderers/CSS2DRenderer";
import { DeviceOrientationControls } from "three/examples/jsm/controls/DeviceOrientationControls.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { VerticalBlurShader } from "three/examples/jsm/shaders/VerticalBlurShader.js";
import { HorizontalBlurShader } from "three/examples/jsm/shaders/HorizontalBlurShader.js";
import { Euler, TextureLoader } from "three";
import Hotspots from "../../../assets/data/base/hotspotData.base.json";
import Modals from "../../../assets/data/base/modals.base.json";
import Photospheres from "../../../assets/data/base/photospheres.base.json";
import { Photosphere, ImageData, Hotspot, Modal } from "../../models";

import {
  ModalService,
  PhotosphereService,
  HeaderService,
  GoogleService,
  MedicalRequestFormWrapperService,
} from "../../services";

import { environment } from "../../../environments/environment";

@Component({
  selector: "app-photosphere",
  templateUrl: "./photosphere.component.html",
  styleUrls: ["./photosphere.component.scss"],
})
export class PhotosphereComponent implements OnInit, OnChanges {
  public environment: any = environment;

  public mainMaterial: THREE.MeshBasicMaterial;
  public clickMaterial: THREE.MeshBasicMaterial;
  public labels: any[] = [];
  public updatedSphere = false;

  // Momentum Stuff.
  private scroll_timeout;
  private linear_tween;
  private momentum_tween;
  private momentumDiff = 0;
  private momentumX = 0;
  private acceleration;
  private startTime;
  private speed;
  private config = {
    momentum: true,
    linear_duration: 0.3, // seconds
    momentum_duration: 1, // seconds
    linear_distance_factor: 4,
    momentum_distance_factor: 10,
    scroll_timeout: 20, // milliseconds
  };

  public currentPhotosphere: Photosphere = Photospheres[0];

  public numSpheres: number = 0;
  public numLoadedSpheres: number = 0;

  public showTransition = true;
  public showCover = false;
  public coverEl: HTMLElement;
  public showPhotosphere = false;
  public modalVisible: boolean = false;
  public photoSphereInteractive: boolean = false;
  public disablePhotosphereRotate: boolean =
    environment.disablePhotosphereRotate;
  public motionControls = false;
  public motionControlsPossible = false;
  public motionControlsPermitted = false;

  public loadedSphere = false;
  public loadedTransition = false;
  public initialized = false;

  public camera: THREE.PerspectiveCamera;
  public controls;
  public scene: THREE.Scene;
  public pickingScene: THREE.Scene;
  public renderer: THREE.WebGLRenderer;
  public composer: EffectComposer;
  public labelRenderer: CSS2DRenderer;
  public cameraTarget: THREE.Vector3;
  public plane: CSS2DObject;
  public olMaterial: THREE.MeshBasicMaterial;
  public ovMaterial: THREE.MeshBasicMaterial;
  public pickHelper: GPUPickHelper;
  public mainTexture: THREE.Texture;
  public aImageData: Array<ImageData> = [];
  public isUserInteracting = false;
  public onMouseDownMouseX = 0;
  public onMouseDownMouseY = 0;
  public lonVal = 135;
  public rotation_tween: gsap.core.Tween = undefined;
  public fov_tween: gsap.core.Tween = undefined;
  public onMouseDownLon = 0;
  public latVal = 0;
  public onMouseDownLat = 0;
  public phi = 0;
  public theta = 0;
  public hasMoved = false;
  public targetRotation = 0;
  public hideprompt = true;

  public udpateUrl = false;
  get lon(): number {
    return this.lonVal;
  }
  set lon(x) {
    if (this.currentPhotosphere.rotationParams?.limitX) {
      this.lonVal = Math.min(
        Math.max(x, this.currentPhotosphere.rotationParams?.limitX[0]),
        this.currentPhotosphere.rotationParams?.limitX[1]
      );
    } else {
      this.lonVal = x;
    }
  }
  get lat(): number {
    return this.latVal;
  }
  set lat(y) {
    if (this.currentPhotosphere.rotationParams?.limitY) {
      this.latVal = Math.min(
        Math.max(y, this.currentPhotosphere.rotationParams?.limitY[0]),
        this.currentPhotosphere.rotationParams?.limitY[1]
      );
    } else {
      this.latVal = y;
    }
  }

  constructor(
    private photosphereSvc: PhotosphereService,
    private modalSvc: ModalService,
    private googleSvc: GoogleService,
    private headerSvc: HeaderService,
    private medicalRequestFormWrapperSvc: MedicalRequestFormWrapperService
  ) {}

  ngOnInit(): void {
    this.coverEl = document.getElementById("photocover");
    this.preloadSpheres();
  }

  subscribe(): void {
    this.photosphereSvc.rotation.subscribe((data) => {
      this.targetRotation = data;
    });

    this.photosphereSvc.manualRotation.subscribe((data) => {
      //console.log(data, 'is the rotation!');
      this.targetRotation = data;
      this.sphereTextureLoaded(null);
    });

    this.photosphereSvc.interactable.subscribe((data) => {
      this.photoSphereInteractive = data;
    });

    this.photosphereSvc.currentPhotosphere.subscribe((data) => {
      if (!this.updatedSphere) {
        this.init();
        this.animate();
        this.updatedSphere = true;
      }

      this.currentPhotosphere = data;
      this.loadNewPhotosphere();
      if (data.id === "center" && this.hideprompt) {
        setTimeout(() => {
          this.hideprompt = false;
        }, 500);
      }
    });

    this.modalSvc.modalVisible.subscribe((data) => {
      this.modalVisible = data;
    });
  }

  ngOnDestroy(): void {
    document.removeEventListener("mousedown", this.onPointerStart);
    document.removeEventListener("mousemove", this.onPointerMove);
    document.removeEventListener("mouseup", this.onPointerUp);
    document.removeEventListener("wheel", this.onDocumentMouseWheel);
    document.removeEventListener("touchstart", this.onPointerStart);
    document.removeEventListener("touchmove", this.onPointerMove);
    document.removeEventListener("touchend", this.onPointerUp);
    window.removeEventListener("resize", this.onWindowResize);
  }

  ngOnChanges(): void {
    this.showCover = true;
    this.showPhotosphere = false;
    this.loadedSphere = false;
    this.loadedTransition = false;
  }

  public preloadSpheres(): void {
    // this.numSpheres = Photospheres.length * 2;
    Photospheres.forEach((p) => {
      // Tony Conti: Add logic around here incase we dont have a transparent layer.
      this.numSpheres++;
      p.mainImageBmp = new THREE.TextureLoader().load(
        "assets/photospheres/" + p.mainImage,
        this.sphereLoaded.bind(this) // On Load Event
      );
      if (p.transparencyImage) {
        this.numSpheres++;
        p.transparencyImageBmp = new THREE.TextureLoader().load(
          "assets/photospheres/" + p.transparencyImage,
          this.sphereLoaded.bind(this) // On Load Event
        );
      }
      if (p.overlayImage) {
        this.numSpheres++;
        p.overlayImgBmp = new THREE.TextureLoader().load(
          "assets/photospheres/" + p.overlayImage,
          this.sphereLoaded.bind(this) // On Load Event
        );
      }
      if (p.clickableImage) {
        this.numSpheres++;
        p.clickableImgBmp = new TextureLoader().load(
          "assets/photospheres/" + p.clickableImage,
          this.sphereLoaded.bind(this)
        );
      }
    });
  }

  public sphereLoaded(): void {
    this.numLoadedSpheres++;

    if (this.numLoadedSpheres >= this.numSpheres) {
      this.photosphereSvc.photosphereLoaded.next(true);
      this.loadedSphere = true;
      this.showTransition = true;
      this.showCover = false;
      this.subscribe();
    }
  }

  public loadNewPhotosphere(): void {
    // The spheres are loaded...
    setTimeout(() => {
      if (this.fov_tween) {
        this.fov_tween.kill();
      }
      setTimeout(() => {
        this.loadedTransition = true;

        if (!this.motionControls && this.targetRotation) {
          this.showTransition = true;
          // @lp also animate zoom
          let zoom = this.currentPhotosphere.zoom
            ? this.currentPhotosphere.zoom
            : 1;
          //this.camera.zoom = zoom;
          this.fov_tween = TweenMax.to(this.camera, 0.6, {
            fov: 65,
            zoom: zoom,
            onUpdate: this.onCameraUpdate.bind(this),
            onComplete: this.zoomComplete.bind(this),
          });
        } else {
          this.showTransition = false;
          this.zoomComplete();
        }
      }, 1);
    }, 1);
  }

  public zoomComplete(): void {
    this.setTextures();
    this.setImageTextures();

    if (this.loadedSphere) {
      this.sphereTextureLoaded(null);
    }
  }

  public sphereTextureLoaded(texture): void {
    // The main Sphere texture is loaded.
    this.loadedSphere = true;

    // Did the texture finish before our little loading delay?
    if (this.loadedTransition) {
      this.showCover = false;
      this.showPhotosphere = true;
      this.initLabels();

      // Scene enter animation.
      if (!this.motionControls && this.targetRotation) {
        const destin = this.targetRotation;
        const fromVal = this.currentPhotosphere.rotationParams.fromX
          ? this.currentPhotosphere.rotationParams.fromX
          : 180;
        const animTime = 2;
        const delay = 0.5;
        TweenMax.to(this.coverEl.style, 0.6, {
          delay: 0.1,
          opacity: 0,
          onComplete: this.coverOffComplete.bind(this),
        });
        this.rotation_tween = TweenMax.fromTo(
          this,
          animTime,
          { lon: fromVal },
          { lon: destin, delay: delay, ease: "expo.out" }
        );
        // @lp also animate zoom
        let zoom = this.currentPhotosphere.zoom
          ? this.currentPhotosphere.zoom
          : 1;
        //this.camera.zoom = zoom;
        this.fov_tween = TweenMax.fromTo(
          this.camera,
          animTime + delay,
          { fov: 55 },
          {
            fov: 75,
            zoom: zoom,
            onUpdate: this.onCameraUpdate.bind(this),
          }
        );
      }
    }
  }

  public coverOffComplete(): void {
    this.showTransition = false;
  }

  public onCameraUpdate(): void {
    this.camera.updateProjectionMatrix();
  }

  public consumeMouseUp(event: Event): void {
    event.stopPropagation();
  }

  public getPhotosphereWidth(): number {
    const widthModifier = environment.persistentMenu
      ? environment.menuType == "default"
        ? 204
        : 315
      : 0;
    return window.innerWidth - widthModifier;
  }

  public getPhotosphereHeight(): number {
    const heightModifier = environment.footerType == "complex" ? 128 : 0;

    return window.innerHeight - heightModifier;
  }

  // THREE JS Methods
  public init(): void {
    this.camera = new THREE.PerspectiveCamera(
      environment.fov || 75,
      this.getPhotosphereWidth() / this.getPhotosphereHeight(),
      0.1,
      1100
    );

    // @lp
    // Set initial zoom, home page only, for the first photosphere only.
    if (
      this.currentPhotosphere &&
      this.currentPhotosphere.zoom &&
      window.location.pathname === "/"
    ) {
      this.camera.zoom = this.currentPhotosphere.zoom;
      this.camera.updateProjectionMatrix();
    } else {
      //this.camera.zoom = 1;
    }

    console.log("Camera Postition: ", this.camera.position);

    this.cameraTarget = new THREE.Vector3(0, 0, 0);

    this.scene = new THREE.Scene();
    this.pickingScene = new THREE.Scene();

    this.resetAssets();

    this.initCycles();

    let container: HTMLElement;
    container = document.getElementById("container");

    this.renderer = new THREE.WebGLRenderer();
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(
      this.getPhotosphereWidth(),
      this.getPhotosphereHeight()
    );
    this.pickHelper = new GPUPickHelper(this.renderer);
    container.appendChild(this.renderer.domElement);

    this.composer = new EffectComposer(this.renderer);
    this.composer.addPass(new RenderPass(this.scene, this.camera));
    let hblur = new ShaderPass(HorizontalBlurShader);
    this.composer.addPass(hblur);

    let vblur = new ShaderPass(VerticalBlurShader);
    // set this shader pass to render to screen so we can see the effects
    vblur.renderToScreen = true;
    this.composer.addPass(vblur);

    this.labelRenderer = new CSS2DRenderer();
    this.labelRenderer.setSize(
      this.getPhotosphereWidth(),
      this.getPhotosphereHeight()
    );
    this.labelRenderer.domElement.style.position = "absolute";
    this.labelRenderer.domElement.style.top = "0px";
    container.appendChild(this.labelRenderer.domElement);

    document.addEventListener(
      "mousedown",
      this.onPointerStart.bind(this),
      false
    );
    document.addEventListener(
      "mousemove",
      this.onPointerMove.bind(this),
      false
    );
    document.addEventListener("mouseup", this.onPointerUp.bind(this), false);
    document.addEventListener(
      "touchstart",
      this.onPointerStart.bind(this),
      false
    );
    document.addEventListener(
      "touchmove",
      this.onPointerMove.bind(this),
      false
    );
    document.addEventListener("touchend", this.onPointerUp.bind(this), false);
    window.addEventListener("resize", this.onWindowResize.bind(this), false);

    if (
      typeof DeviceMotionEvent !== "undefined" &&
      typeof DeviceMotionEvent.requestPermission === "function"
    ) {
      this.motionControlsPossible = true;
      this.promptMotionControls();
    } else if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
      this.motionControlsPossible = true;
      this.permitMotionControls();
    }
  }

  public resetAssets(): void {
    let mesh: THREE.Object3D;
    let olMesh: THREE.Object3D;
    let ovMesh: THREE.Object3D;
    let clickMesh: THREE.Object3D;

    const geometry = new THREE.SphereBufferGeometry(500, 60, 40);
    // invert the geometry on the x-axis so that all of the faces point inward
    geometry.scale(-1, 1, 1);

    this.mainTexture = this.currentPhotosphere.mainImageBmp;
    this.sphereTextureLoaded(null);
    this.mainMaterial = new THREE.MeshBasicMaterial({ map: this.mainTexture });
    this.mainMaterial.needsUpdate = true;
    mesh = new THREE.Mesh(geometry, this.mainMaterial);

    if (this.currentPhotosphere.transparencyImage) {
      const olGeometry = new THREE.SphereBufferGeometry(400, 60, 40);
      // invert the geometry on the x-axis so that all of the faces point inward
      olGeometry.scale(-1, 1, 1);

      const olTexture = this.currentPhotosphere.transparencyImageBmp;
      this.olMaterial = new THREE.MeshBasicMaterial({
        map: olTexture,
        transparent: true,
      });
      this.olMaterial.needsUpdate = true;

      olMesh = new THREE.Mesh(olGeometry, this.olMaterial);
      this.scene.add(olMesh);
    }

    if (this.currentPhotosphere.overlayImage) {
      const ovGeometry = new THREE.SphereBufferGeometry(15, 60, 40);
      // invert the geometry on the x-axis so that all of the faces point inward
      ovGeometry.scale(-1, 1, 1);

      const ovTexture = this.currentPhotosphere.overlayImgBmp;
      this.ovMaterial = new THREE.MeshBasicMaterial({
        map: ovTexture,
        transparent: true,
      });
      this.ovMaterial.needsUpdate = true;

      ovMesh = new THREE.Mesh(ovGeometry, this.ovMaterial);
      this.scene.add(ovMesh);
    }

    if (this.currentPhotosphere.clickableImage) {
      const clickGeometry = new THREE.SphereBufferGeometry(300, 60, 40);
      // invert the geometry on the x-axis so that all of the faces point inward
      clickGeometry.scale(-1, 1, 1);

      const clickTexture = this.currentPhotosphere.clickableImgBmp;
      this.clickMaterial = new THREE.MeshBasicMaterial({ map: clickTexture });
      this.clickMaterial.needsUpdate = true;

      clickMesh = new THREE.Mesh(clickGeometry, this.clickMaterial);
      this.pickingScene.add(clickMesh);
    }

    this.scene.add(mesh);
    this.hasMoved = false;
    this.setImageTextures();
  }

  setImageTextures(): void {
    this.aImageData.forEach((imgData) => {
      imgData.mesh.visible = false;
    });

    if (this.currentPhotosphere.images) {
      this.currentPhotosphere?.images.forEach((img) => {
        let bNew = true;
        const x = img.x;
        const y = img.y;
        const z = img.z;
        const rot = img.rotation;
        const imgOld = this.aImageData.find((i) => i.id === img.id);
        if (imgOld) {
          bNew = false;
          img = imgOld;
          img.mesh.visible = true;
        }

        if (bNew) {
          img.mat = new THREE.MeshBasicMaterial({
            map: new THREE.TextureLoader().load("assets/images/" + img.url),
            side: THREE.DoubleSide,
            transparent: true,
          });
          img.geo = new THREE.PlaneBufferGeometry(16.76, 10.34, 4, 4);
          img.mesh = new THREE.Mesh(img.geo, img.mat);
          this.scene.add(img.mesh);
          this.aImageData.push(img);
        }

        const pos = this.getPositionFromEquirectangularSpace(x, y);
        img.mesh.position.set(pos.x, pos.y, pos.z);
        img.mesh.rotation.set(0, 0, 0);
        img.mesh.scale.set(z, z, 1);
        img.mesh.rotateOnAxis(
          new THREE.Vector3(0, 1, 0),
          (rot * Math.PI) / 180
        );
        // img.mesh.lookAt(new THREE.Vector3(0,0,0));
        img.mesh.updateWorldMatrix(true, false);
      });
    }
  }

  setTextures() {
    this.mainTexture = this.currentPhotosphere.mainImageBmp;
    this.mainMaterial.map = this.mainTexture;
    this.mainTexture.needsUpdate = true;

    if (this.currentPhotosphere.transparencyImage) {
      this.olMaterial.map = this.currentPhotosphere.transparencyImageBmp;
      this.olMaterial.needsUpdate = true;
    }

    if (this.currentPhotosphere.overlayImage) {
      this.ovMaterial.map = this.currentPhotosphere.overlayImgBmp;
      this.ovMaterial.needsUpdate = true;
    }

    if (this.currentPhotosphere.clickableImage) {
      this.clickMaterial.map = this.currentPhotosphere.clickableImgBmp;
      this.clickMaterial.needsUpdate = true;
    }

    this.lon = this.currentPhotosphere.rotationParams.startX;
    this.lat = this.currentPhotosphere.rotationParams.startY ?? 0;
    this.loadedSphere = true;
  }

  public promptMotionControls() {
    DeviceMotionEvent.requestPermission()
      .then((permissionState) => {
        if (permissionState === "granted") {
          this.permitMotionControls();
        }
      })
      .catch(console.error);
  }

  public permitMotionControls() {
    // window.addEventListener('deviceorientation', this.handleOrientation.bind(this), true);
    this.motionControls = true;
    this.motionControlsPermitted = true;
    this.controls = new DeviceOrientationControls(this.camera);
  }

  public toggleMotionControls() {
    if (!this.motionControlsPossible) {
      return;
    }

    this.hasMoved = true;

    if (this.motionControlsPermitted) {
      this.motionControls = !this.motionControls;
    } else {
      this.promptMotionControls();
    }
  }

  public handleMotion(event: any): void {
    this.lon += event.acceleration.x;
    this.lat += event.acceleration.y;
  }

  public handleOrientation(event: any): void {
    if (this.motionControls) {
      const gamma = event.gamma;
      const beta = event.beta;
      const alpha = event.alpha;
      const euler = new Euler();
      euler.set(beta, alpha, -gamma, "YXZ");
      this.lat = -90 + euler.x + this.currentPhotosphere.rotationParams.startX;
      this.lon = -90 - euler.y;
    }
  }

  public initLabels(): void {
    this.labels.forEach((label) => {
      this.scene.remove(label);
      label.remove();
    });

    this.currentPhotosphere.hotspots.forEach((element) => {
      if (element.disabled) return;

      const label = this.makeLabelObject(element);
      this.scene.add(label);
      this.labels.push(label);
    });
  }

  public makeLabelObject(hotspot): CSS2DObject {
    const hotspotData: any = Hotspots.find((el) => el.id === hotspot.dataId);

    const text = document.createElement("div");
    text.className = "photosphere-flag";

    text.className += hotspot.chat ? " chat-flag" : "";
    text.className += hotspot.large ? " large" : "";
    text.className += hotspot.tall ? " tall" : "";
    text.className += hotspot.navigation ? " nav" : " hs";

    if (!hotspot.chat) {
      text.appendChild(this.addHotspotSvg(hotspot));
    }

    text.onclick = (event) => {
      this.hotspotClick(hotspot);
    };

    // text.onmouseenter = (event) => {
    //   if (window.innerHeight >= 865) return;
    //   document.getElementsByClassName('header').item(0).classList.remove('on');
    // };

    // text.onmouseleave = (event) => {
    //   if (window.innerHeight >= 865) return;
    //   document.getElementsByClassName('header').item(0).classList.add('on');
    // };

    if (hotspotData.hoverText) {
      text.appendChild(
        this.createHoverText(
          hotspotData.hoverText,
          hotspotData.hoverCssClass || null
        )
      );
    }

    if (hotspotData.extraHover) {
      text.appendChild(this.createHoverExtra(hotspotData.extraHover));
    }

    const label = new CSS2DObject(text);
    const forward = this.getPositionFromEquirectangularSpace(
      hotspot.x * this.currentPhotosphere.mainScale,
      hotspot.y * this.currentPhotosphere.mainScale
    );
    label.position.x = forward.x;
    label.position.y = forward.y;
    label.position.z = forward.z;

    return label;
  }

  public getPositionFromEquirectangularSpace(x, y): THREE.Vector3 {
    const xfrac = x / this.mainTexture.image?.width;
    const yfrac = y / this.mainTexture.image?.height;
    const xrot = 2 * Math.PI * xfrac;
    const yrot = Math.PI * (yfrac - 0.5);
    const forward = new THREE.Vector3(50, 0, 0);
    forward.applyAxisAngle(new THREE.Vector3(0, 0, -1), yrot);
    forward.applyAxisAngle(new THREE.Vector3(0, -1, 0), xrot);
    return forward;
  }

  public createHoverText(hoverCopy: any, cssClass: string = null): any {
    const hoverDiv = document.createElement("div");
    hoverDiv.classList.add("hotspot-bubble");

    if (cssClass) {
      hoverDiv.classList.add(cssClass);
    }

    let htmlString = "";

    if (typeof hoverCopy === "string") {
      htmlString = `<p>${hoverCopy}</p>`;
    } else {
      for (const line of hoverCopy) {
        htmlString += line;
      }
    }

    hoverDiv.innerHTML = htmlString;
    return hoverDiv;
  }

  public createHoverExtra(extraHover: any): any {
    const hoverDiv = document.createElement("div");
    hoverDiv.classList.add("hotspot-extra");
    hoverDiv.classList.add(
      extraHover.position ? extraHover.position : "bottom"
    );

    if (extraHover.position === "top") {
      hoverDiv.classList.add("secondary");
    }

    if (extraHover.text) {
      hoverDiv.innerHTML = extraHover.text;
      if (extraHover.text_size) {
        hoverDiv.style.fontSize = extraHover.text_size + "px";
      }
    } else if (extraHover.image) {
      const img = document.createElement("img");
      img.src = extraHover.image;
      if (extraHover.image_max_width) {
        img.style.maxWidth = extraHover.image_max_width + "px";
      }
      hoverDiv.append(img);
    }

    return hoverDiv;
  }

  public addHotspotSvg(hotspot: any): any {
    const icon = document.createElement("div");
    icon.classList.add("icon");
    let htmlString = "";

    if (hotspot.navigation) {
      htmlString =
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 37"><polygon points="37 18.5 33.76 21.73 21 8.97 21 37 16 37 16 8.97 3.24 21.73 0 18.5 18.5 0 37 18.5"/></svg>';
    } else {
      htmlString =
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 37"><polygon points="37 16 37 21 21 21 21 37 16 37 16 21 0 21 0 16 16 16 16 0 21 0 21 16 37 16"/></svg>';
    }

    if (hotspot.rotation) {
      icon.style.transform = `rotate(${hotspot.rotation}deg)`;
    }

    icon.innerHTML = htmlString;
    return icon;
  }

  public createElementFromHTML(htmlString): Node {
    const div = document.createElement("div");
    div.innerHTML = htmlString;
    return div.firstChild;
  }

  public hotspotClick(hotspot: Hotspot): void {

    //console.log(hotspot, "is the clicked hotspot!");
    this.googleSvc.sendHotspotEvent(hotspot);

    this.hasMoved = true;

    let clickedData: any = Hotspots.find((el) => el.id === hotspot.dataId);

    if (clickedData.photosphere) {
      const photosphereData: any = Photospheres.find(
        (x) => x.id === clickedData.photosphere
      );
      if (photosphereData.showControlsPrompt) this.hasMoved = false;
      this.goToPhotosphere(clickedData);
    }

    if (clickedData.modal) {
      if (!clickedData.photosphere) {
        this.openModal(clickedData);
      } else {
        setTimeout(() => {
          this.openModal(clickedData);
        }, 1500);
      }
    }

    if (clickedData.chat) {
      this.photosphereSvc.openChat();
    }

    if (clickedData.href) {
      console.log(hotspot, clickedData, "clickedData");
      // @analytics
      this.googleSvc.sendExternalLinkEvent(hotspot.gtmTitle, clickedData.href);
      window.open(clickedData.href, "_blank");
    }

    if (clickedData.medForm) {
      this.medicalRequest();
    }
  }

  public goToPhotosphere(clickedData: any) {
    this.photosphereSvc.setCurrentPhotosphere(
      clickedData.photosphere,
      clickedData.rotation ? clickedData.rotation : undefined,
      true
    );
  }

  public openModal(clickedData: any) {
    let data: Modal = Modals.find((el) => el.id === clickedData.modal) as Modal;

    /*
    // We'll handle GA tracking inside modal component instead
    if (data.gtm_id > -1) {
      console.log("SEND GA #1");
      this.googleSvc.sendGAEventById(data.gtm_id);
    } else {
      console.log(data);
      console.log("SEND GA #3");
      this.googleSvc.sendGAEventById(data.modal_type, {
        eventCategory: data.title,
        eventLabel: data.src,
      });
    }
    */

    this.modalSvc.setActiveModalByData(data);
  }

  public medicalRequest(): void {
    this.medicalRequestFormWrapperSvc.open();
  }

  public onWindowResize(): void {
    this.camera.aspect =
      this.getPhotosphereWidth() / this.getPhotosphereHeight();
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(
      this.getPhotosphereWidth(),
      this.getPhotosphereHeight()
    );
    this.labelRenderer.setSize(
      this.getPhotosphereWidth(),
      this.getPhotosphereHeight()
    );
  }

  public onPointerStart(event): void {
    if (
      !this.photoSphereInteractive ||
      this.modalVisible ||
      event.button === 2
    ) {
      return;
    }

    this.isUserInteracting = true;

    const clientX = event.clientX || event.touches[0].clientX;
    const clientY = event.clientY || event.touches[0].clientY;

    //console.log(clientX + ", " + clientY + " are the x/y coords")

    if (clientY < 65 || clientY > window.innerHeight - 140) {
      this.isUserInteracting = false;
      return;
    }

    this.onMouseDownMouseX = clientX;
    this.onMouseDownMouseY = clientY;

    this.onMouseDownLon = this.lon;
    this.onMouseDownLat = this.lat;
    this.momentumDiff = 0;
    this.momentumX = clientX;
    this.startTime = new Date();

    if (this.momentum_tween) {
      this.momentum_tween.kill();
    }

    if (this.rotation_tween) {
      this.rotation_tween.kill();
    }
  }

  public onPointerMove(event): void {
    if (this.disablePhotosphereRotate) {
      return;
    }
    if (!this.photoSphereInteractive || this.modalVisible) {
      return;
    }
    if (this.isUserInteracting === true) {
      const clientX = event.clientX || event.touches[0].clientX;
      const clientY = event.clientY || event.touches[0].clientY;

      this.lon = (this.onMouseDownMouseX - clientX) * 0.1 + this.onMouseDownLon;
      this.lat = (clientY - this.onMouseDownMouseY) * 0.1 + this.onMouseDownLat;
      //console.log(this.lat + ", " + this.lon);
      this.hasMoved = true;

      if (this.scroll_timeout) {
        clearTimeout(this.scroll_timeout);
      }

      // when no new scroll event fires, tween momentum effect
      if (this.config.momentum) {
        this.scroll_timeout = setTimeout(() => {
          this.momentumDiff = (this.momentumX - clientX) * 0.1;
          // this.tweenMomentum(this.onMouseDownLon - this.lon);
          if (!this.isUserInteracting) {
            const now: any = new Date();
            const time: any = (now - this.startTime) * 0.02;
            this.speed = this.momentumDiff / time;
            this.acceleration = this.speed / time;

            this.tweenMomentum(this.acceleration);
          } else {
            // this.momentumX = clientX;
          }
        }, this.config.scroll_timeout);
      }
    } else {
      const id = this.pickHelper.pick(
        { x: event.clientX, y: event.clientY },
        this.pickingScene,
        this.camera
      );
      if (id === 0xff00ff) {
        document.body.style.cursor = "pointer";
      } else {
        document.body.style.cursor = "auto";
      }
    }
  }

  public onPointerUp(event): void {
    this.isUserInteracting = false;
  }

  public tweenLinearMomentum(x: number): void {
    if (this.momentum_tween) {
      this.momentum_tween.kill();
    }

    this.linear_tween = TweenMax.to(this, this.config.linear_duration, {
      lon: `+=${this.config.linear_distance_factor * x}`,
      ease: Power0.easeNone,
    });
  }

  public tweenMomentum(x: number): void {
    if (this.linear_tween) {
      this.linear_tween.kill();
    }

    this.momentum_tween = TweenMax.to(this, this.config.momentum_duration, {
      lon: `+=${this.config.momentum_distance_factor * x}`,
      ease: Power2.easeOut,
    });
  }

  hexToBin(hex: any): number {
    const r = hex >> 16;
    const g = (hex >> 8) & 0xff;
    const b = hex & 0xff;
    const id = (r << 16) | (g << 8) | (b << 0);

    return id;
  }

  public onDocumentMouseWheel(event): void {
    if (!this.photoSphereInteractive || this.modalVisible) {
      return;
    }

    const fov = this.camera.fov + event.deltaY * 0.05;

    this.camera.fov = THREE.MathUtils.clamp(fov, 30, 70);

    this.camera.updateProjectionMatrix();
  }

  public animate(): void {
    requestAnimationFrame(this.animate.bind(this));
    this.update();
  }

  public update(): void {
    if (this.showPhotosphere) {
      if (this.motionControls) {
        this.controls.update();

        const cameraVector = new THREE.Vector3();
        this.camera.getWorldDirection(cameraVector);
        cameraVector.y = 0;
        const zeroVector = new THREE.Vector3(1, 0, 0);
        let angle = (zeroVector.angleTo(cameraVector) * 180) / Math.PI;
        if (cameraVector.z < 0) {
          angle = 360 - angle;
        }
        this.photosphereSvc.currentRotation.next(angle);
      } else {
        this.lat = Math.max(-85, Math.min(85, this.lat));
        this.phi = THREE.MathUtils.degToRad(90 - this.lat);
        this.theta = THREE.MathUtils.degToRad(this.lon);

        this.cameraTarget.x = 550 * Math.sin(this.phi) * Math.cos(this.theta);
        this.cameraTarget.y = 550 * Math.cos(this.phi);
        this.cameraTarget.z = 550 * Math.sin(this.phi) * Math.sin(this.theta);
        this.camera.lookAt(this.cameraTarget);

        const cameraVector = new THREE.Vector3(
          this.cameraTarget.x,
          0,
          this.cameraTarget.z
        );
        const zeroVector = new THREE.Vector3(1, 0, 0);
        let angle = (zeroVector.angleTo(cameraVector) * 180) / Math.PI;
        if (cameraVector.z < 0) {
          angle = 360 - angle;
        }
        this.photosphereSvc.currentRotation.next(angle);
      }

      if (!this.photosphereSvc.blurred.value) {
        this.renderer.render(this.scene, this.camera);
      } else {
        this.composer.render();
      }

      if (this.labelRenderer) {
        this.labelRenderer.render(this.scene, this.camera);
      }
    }
  }

  /**
   * Photosphere image cycler / slider
   */
  public cycleTextures: Array<THREE.Texture> = [];
  public cycleIndex: number = -1;
  public cycleTimeout: any = null;
  public cyclerMesh: THREE.Mesh;
  public cyclerMaterial: THREE.MeshBasicMaterial;

  public hasCycler(): boolean {
    return this.currentPhotosphere && !!this.currentPhotosphere["enableCycler"];
  }

  public loadCycleTextures(): void {
    if (!this.hasCycler() || this.cycleTextures.length > 0) {
      return;
    }
    const textureLoader = new THREE.TextureLoader();
    this.cycleTextures.push(
      textureLoader.load(`assets/images/ddw/cycle/Cycle_Banner_01-fs8.png`)
    );
    this.cycleTextures.push(
      textureLoader.load(`assets/images/ddw/cycle/Cycle_Banner_02-fs8.png`)
    );
    this.cycleTextures.push(
      textureLoader.load(`assets/images/ddw/cycle/Cycle_Banner_03-fs8.png`)
    );
    this.cycleTextures.push(
      textureLoader.load(`assets/images/ddw/cycle/Cycle_Banner_04-fs8.png`)
    );
  }

  public setCyclerPosition(): void {
    if (!this.hasCycler()) {
      return;
    }
    const position = this.currentPhotosphere.cycler;
    this.cyclerMesh.position.set(position.x, position.y, position.z);
    this.cyclerMesh.rotation.set(0, 0, 0);
    this.cyclerMesh.rotateOnAxis(
      new THREE.Vector3(0, 1, 0),
      (position.rotation * Math.PI) / 180
    );
  }

  public initCycles(): void {
    if (!this.hasCycler()) {
      return;
    }
    this.loadCycleTextures();
    const cyclerGeo = new THREE.SphereBufferGeometry(15, 60, 40);
    cyclerGeo.scale(-1, 1, 1);
    this.cyclerMaterial = new THREE.MeshBasicMaterial({
      map: this.cycleTextures[0],
      transparent: true,
    });
    this.cyclerMaterial.needsUpdate = true;
    this.cyclerMesh = new THREE.Mesh(cyclerGeo, this.cyclerMaterial);
    this.scene.add(this.cyclerMesh);
    if (this.cycleTimeout) {
      clearInterval(this.cycleTimeout);
    }
    let me = this;
    this.nextCyclerTexture();
    setInterval(function () {
      me.nextCyclerTexture();
    }, 6000);
  }

  public nextCyclerTexture(): void {
    this.cycleIndex++;
    if (this.cycleIndex >= this.cycleTextures.length) {
      this.cycleIndex = 0;
    }
    this.cyclerMaterial.map = this.cycleTextures[this.cycleIndex];
  }
}

class GPUPickHelper {
  public pickingTexture: THREE.WebGLRenderTarget;
  public pixelBuffer: Uint8Array;
  public renderer: THREE.WebGLRenderer;

  constructor(r: THREE.WebGLRenderer) {
    // create a 1x1 pixel render target
    this.pickingTexture = new THREE.WebGLRenderTarget(1, 1);
    this.pixelBuffer = new Uint8Array(4);
    this.renderer = r;
  }
  pick(cssPosition, scene, camera) {
    const { pickingTexture, pixelBuffer } = this;

    // set the view offset to represent just a single pixel under the mouse
    const pixelRatio = this.renderer.getPixelRatio();
    camera.setViewOffset(
      this.renderer.getContext().drawingBufferWidth, // full width
      this.renderer.getContext().drawingBufferHeight, // full top
      (cssPosition.x * pixelRatio) | 0, // rect x
      (cssPosition.y * pixelRatio) | 0, // rect y
      1, // rect width
      1 // rect height
    );
    // render the scene
    this.renderer.setRenderTarget(pickingTexture);
    this.renderer.render(scene, camera);
    this.renderer.setRenderTarget(null);
    // clear the view offset so rendering returns to normal
    camera.clearViewOffset();
    // read the pixel
    this.renderer.readRenderTargetPixels(
      pickingTexture,
      0, // x
      0, // y
      1, // width
      1, // height
      pixelBuffer
    );

    const id =
      (pixelBuffer[0] << 16) | (pixelBuffer[1] << 8) | (pixelBuffer[2] << 0);
    if (pixelBuffer[3] == 0) {
      return 0;
    }

    return id;
  }
}
