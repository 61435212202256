import { Injectable } from '@angular/core';
import { ModalType } from '../models';
import { GoogleService } from './google-analytics.service';
import { ModalService } from './modal.service';

@Injectable({
  providedIn: 'root',
})
export class MedicalRequestFormWrapperService {
  private medForm: any;
  private eventListenerAdded: boolean = false;

  constructor(
    private modalSvc: ModalService,
    private googleSvc: GoogleService
  ) {}

  public init() {
    this.medForm = window['medicalForm'];
    if (!this.eventListenerAdded) this.eventListenerAdded = true;
    window.addEventListener(
      'HoudiniSays',
      this.onMedicalFormModalClosed.bind(this)
    );
    window.addEventListener(
      'hiMIRFEvent',
      this.onMedicalFormSubmitted.bind(this)
    );
  }

  public open() {
    //this.modalSvc.setActiveModalById("med-form");
    this.googleSvc.sendGAEventById("med-form");
    this.medForm.open();
  }

  public close() {
    this.medForm.close();
  }

  private onMedicalFormModalClosed(e: any) {
    if (e.detail === 'modalClose') {
      this.googleSvc.sendGAEventById("med-form-closed");
      this.modalSvc.hideModal();
    }
  }

  private onMedicalFormSubmitted(e: any) {
    this.googleSvc.sendGAEventById("med-form-submitted");
  }

}
