export const commonEnv = {
  assetBasePath: '',
  showName: 'eunn',
  fov: 75,
  persistentMenu: false,
  menuButtonType: "default",
  menuTitle: 'Product Portfolio',
  menuType: 'default',
  menuTooltips: false,
  footerType: 'default',
  disablePhotosphereRotate: false,
  showHomeLink: false,
  homeLinkPhotosphereId: 'home',
  showExitLink: true,
  exitLinkType: 'photosphere',
  exitLinkValue: 'center',
  showMenuButtonWhenHeaderVisible: false,
  autoOpenMenuOnHome: false,
  welcomeSettings: {
    countryGate: true,
    rememberHcpGate: true,
    title: 'Welcome to the<br />Takeda Virtual Experience',
    veevaCode: 'VV-MEDMAT-50148 09/2021',
    disclaimer:
      'The information on this website is intended for Healthcare Professionals',
    footerCopy:
    'Copyright &copy;&nbsp;2021 Takeda Pharmaceutical Company Limited.<br />TAKEDA and the TAKEDA logo are trademarks or registered trademarks of Takeda Pharmaceutical Company<br />Limited. All other Trademarks appearing herein are the property of their respective owners.',
    showFooter: true,
    redirectNotice: 'The information is intended for EU Healthcare Professionals only.'
  },
  footerCopy: 'The information on this website is intended for EU Healthcare Professionals only. <br/>The investigational compounds listed in this Medical Affairs Booth have not been approved for use by the <br/>U.S. Food and Drug Administration, the European Medicines Agency or any other regulatory authority.',
  contentUrlRules: [
    {
      targetHosts: [ 'localhost', 'eunn2021-stage.websitemagic.dev' ],
      contentUrl: 'https://eunn2021-stage.websitemagic.dev/final-content'
    },
    {
      targetHosts: [ 'eunn.medical.takedaneuroscience.com' ],
      contentUrl: 'https://eunn.medical.takedaneuroscience.com/final-content'
    }
  ]
};
